import axios from '../libs/axios';
// 积分规则
const promotionList = (data) => {
  return axios.get('/api/trd/promotion/list', data);
};

// 积分规则
const promotionAllList = (data) => {
  return axios.get('/api/trd/promotion/listAll', data);
};

// 新增/修改
const promotionAddOrEdit = (data) => {
  return axios.post('/api/trd/promotion/save', data);
};

// 信息
const promotionInfo = (promotionId) => {
  return axios.post(`/api/trd/promotion/info/${promotionId}`);
};

const promotionStatus = (data) => {
  return axios.post('/api/trd/promotion/status', data);
};

// 删除
const promotionDelete = (data) => {
  return axios.post('/api/trd/promotion/delete', data);
};

export {
  promotionList,
  promotionAllList,
  promotionAddOrEdit,
  promotionInfo,
  promotionStatus,
  promotionDelete
};
