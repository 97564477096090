<template>
  <div class="base-content">
    <form-frame
      :reset-visible="!isDetail"
      :submit-visible="!isDetail"
      @on-back="handleBack"
      @on-reset="handleReset"
      @on-submit="handleSubmit">
      <div slot="content">
        <a-card title="同行立减规则">
          <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            :label-col="{span:8}"
            :wrapper-col="{span:14}">
<!--            <a-row>-->
<!--              <a-col :span="10">-->
<!--                <a-form-model-item label="同行立减名称" prop="promotionName">-->
<!--                  <a-input v-model="form.promotionName" :maxLength="50" :disabled="isDetail" allowClear></a-input>-->
<!--                </a-form-model-item>-->
<!--              </a-col>-->
<!--            </a-row>-->
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="同行人数：" prop="firstcount">
                  <a-row :gutter="16">
                    <a-col :span="8">
                      <a-input-number style="width: 100%" size="large" v-model="form.firstCount" :min="0" :max="9999999999" :disabled="isDetail"></a-input-number>
                    </a-col>
                    <a-col :span="2">人</a-col>
                  </a-row>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="立减人数：" prop="lastCount">
                  <a-row :gutter="16">
                    <a-col :span="8">
                      <a-input-number style="width: 100%" size="large" v-model="form.lastCount" :min="0" :max="9999999999" :disabled="isDetail"></a-input-number>
                    </a-col>
                    <a-col :span="2">人</a-col>
                  </a-row>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="备注" prop="remarks">
                  <a-input v-model="form.remarks" type="textarea" :auto-size="{ minRows: 4, maxRows: 6 }" :disabled="isDetail"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="规则状态" prop="status">
                  <a-switch checked-children="启用" un-checked-children="禁用" :checked="form.status === 0" @change="handleChangeStatus" :disabled="isDetail" />
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-card>
      </div>
    </form-frame>
  </div>
</template>

<script>
import { promotionAddOrEdit, promotionInfo } from '@/api/promotion';
import formMixin from '@/mixin/formMixin';

export default {
  name: 'PromotionForm',
  mixins: [formMixin],
  data() {
    return {
      form: {
        firstCount: 3,
        lastCount: 1,
        status: 0,
        remarks: ''
      },
      rules: {
        firstCount: [
          {
            required: true,
            message: '请输入同行人数',
            trigger: 'blur',
          },
          {
            validator: (rule, value, callback) => {
              if (this.form.firstCount < this.form.lastCount) {
                callback(new Error('立减人数不能高于同行人数'));
              }
              callback();
            },
          }
        ],
        lastCount: [
          {
            required: true,
            message: '请输入立减人数',
            trigger: 'blur',
          },
          {
            validator: (rule, value, callback) => {
              if (this.form.firstCount < this.form.lastCount) {
                callback(new Error('立减人数不能高于同行人数'));
              }
              callback();
            },
          }
        ],
      },
    };
  },
  created() {
    if (this.paramsId) {
      this.getData(this.paramsId);
    }
  },
  computed: {
    promotionRule() {
      return `${this.form.firstCount}-${this.form.lastCount}`;
    },
    promotionName() {
      return `同行${this.form.firstCount}人立减${this.form.lastCount}人`;
    }
  },
  methods: {
    // 获取信息
    getData(promotionId) {
      promotionInfo(promotionId).then((data) => {
        if (data) {
          const arr = data.promotionRule.split('-');
          data.firstCount = arr[0];
          data.lastCount = arr[1];
        }
        this.form = data;
        // console.log(data);
      });
    },

    // 提交
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = {
            ...this.form,
          };
          console.log(params);
          params.promotionRule = this.promotionRule;
          params.promotionName = this.promotionName;
          params.status === params.status ? 1 : 0;
          promotionAddOrEdit(params).then((res) => {
            if (res) {
              this.$message.success(res.message || '操作成功');
              this.handleBack();
            } else {
              this.$message.error('等级名称请勿重复');
              return false;
            }
          });
        } else {
          this.$message.error('请按要求填写');
          return false;
        }
      });
    },
    handleChangeStatus(status) {
      this.form.status = status ? 0 : 1;
    }
  }
};
</script>

<style lang="less" scoped>

</style>
